/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useLabels } from 'wsm-common-data';
import Skeleton from 'wsm-skeleton';

const FilterItemListSkeleton = ({ enableHLFacet }) => {
	const { filterParams, serverRender } = useSelector(
		(state) => state.widgetInfo
	);
	const labels = useLabels();
	const count = filterParams.length;

	const skeletonRender = (
		<div className="row gap-skeleton">
			<div className="col-xs-6 col-md-8">
				<Skeleton aspectRatio="4by3" height="38px" />
			</div>
			<div className="col-xs-6 col-md-4">
				<Skeleton aspectRatio="4by3" height="38px" />
			</div>
		</div>
	);

	const filterItemListSSR = (
		<div className="facet-filters-ref">
			<div className="facet-filters d-flex align-items-baseline flex-wrap flex-nowrap-lg">
				<div className="d-lg-flex align-items-baseline">
					<ul className="facet-filter-list mb-0 list-inline">
						<li className="my-1">
							<strong className="d-inline-block flex-shrink-0 mr-3" />
						</li>

						{filterParams.length > 0 &&
							filterParams.map((item) => {
								return (
									<li
										className="my-1 d-none d-lg-inline-block"
										key={item}
									>
										<button
											type="button"
											className="pill chip chip-default chip-condensed facet-filter"
										>
											{item}
											<i className="ddc-icon ddc-icon-remove2" />
										</button>
									</li>
								);
							})}

						{filterParams.length > 0 && (
							<li className="my-1 d-none d-lg-inline-block">
								<button
									type="button"
									className="facet-filter text-link d-none d-lg-inline-block"
								>
									{labels.get('CLEAR_FILTERS')}
								</button>
							</li>
						)}
					</ul>
				</div>
				<div className="ml-auto d-lg-none">
					<div className="flex-grow-1">
						<button
							type="button"
							id="show-filters-modal-button"
							className="btn btn-default btn-block ddc-font-size-large font-weight-bold"
						>
							<i className="ddc-icon ddc-icon-filter-list-controls" />
							{labels.get('FILTER_SLASH_SORT')}
							{count ? (
								<div className="badge mb-0">{count}</div>
							) : null}
						</button>
					</div>
				</div>
				<div className="ml-auto flex-shrink-0 d-none d-lg-block">
					<label htmlFor="sortBy" className="sr-only">
						Sort by
					</label>
					<select
						id="sortBy"
						className="form-control font-size-ios-zoom-override"
					>
						<option value="">Sort by</option>
						<option value="odometer asc">
							Mileage: Low to High
						</option>
					</select>
				</div>
			</div>
		</div>
	);

	return (
		<>
			{enableHLFacet && (
				<div>
					<Skeleton
						skeletonClasses="mb-4"
						aspectRatio="4by3"
						height="38px"
					/>
				</div>
			)}

			{!serverRender ? skeletonRender : filterItemListSSR}
		</>
	);
};

FilterItemListSkeleton.propTypes = {
	enableHLFacet: PropTypes.bool.isRequired
};

export default FilterItemListSkeleton;
