import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLabels, useRequestData } from 'wsm-common-data';
import { DIRECTION } from '../global-constants';
import { addFilter } from '../utilities/addFilter';
import Navigator from './Navigator';
import { useDrag } from '../hooks/useDrag';
import { useScrollPosition } from '../hooks/useScrollPosition';
import { useWheelEffect, scrollWithRAF } from '../hooks/useWheelEffect';

const classes = {
	SHOW: 'show',
	HIDE: 'hide'
};

const HighlightFacet = ({
	highlightFacet = {},
	chipColorClasses = '',
	showCountOnPill
}) => {
	const labels = useLabels();
	const { widgetName, windowId, deviceType } = useRequestData();
	const scrollContainerRef = useRef(null);
	const leftNavigator = useRef(null);
	const rightNavigator = useRef(null);
	const overlayRef = useRef(null);
	const highlightFacetValues =
		highlightFacet?.values?.filter((item) => !item.selected) || [];
	const { id: facetId, label: facetLabel } = highlightFacet;
	const isMobile = deviceType === 'MOBILE';

	const { dragStart, dragStop, dragMove, dragging } = useDrag();

	const handleDrag = useCallback(
		(e) => {
			dragMove(e, (posDiff) => {
				if (scrollContainerRef.current) {
					// eslint-disable-next-line no-param-reassign
					scrollContainerRef.current.scrollLeft += posDiff;
				}
				e.target.blur();
			});
		},
		[dragMove]
	);

	const mouseUpHandler = useCallback(
		(e) => {
			scrollContainerRef.current.style.cursor = 'grab';
			scrollContainerRef.current.style.removeProperty('user-select');
			dragStop();
			document.removeEventListener('mouseup', mouseUpHandler);
			e.target.blur();
		},
		[dragStop]
	);

	const mouseDownHandler = useCallback(
		(e) => {
			scrollContainerRef.current.style.cursor = 'grabbing';
			scrollContainerRef.current.style.userSelect = 'none';
			document.addEventListener('mouseup', mouseUpHandler);
			dragStart(e);
		},
		[dragStart, mouseUpHandler]
	);

	useWheelEffect(scrollContainerRef);

	useScrollPosition(
		() => {
			if (!scrollContainerRef.current || isMobile) {
				return;
			}
			const { left, right } =
				scrollContainerRef.current.getBoundingClientRect();
			const children = scrollContainerRef.current.querySelectorAll(
				'.highlight-facet-btn'
			);
			const leftOfFirstEl = children[0].getBoundingClientRect().left;
			const rightOfLastEl =
				children[children.length - 1].getBoundingClientRect().right;
			// Calculate show/hide for left navigator
			if (left > leftOfFirstEl) {
				leftNavigator.current.classList.add(classes.SHOW);
			} else {
				leftNavigator.current.classList.remove(classes.SHOW);
			}
			// Calculate show/hide for right navigator
			if (right < rightOfLastEl) {
				if (left === leftOfFirstEl) {
					rightNavigator.current.classList.remove(
						classes.SHOW,
						classes.HIDE
					);
					overlayRef.current.style.display = 'block';
					return;
				}
				overlayRef.current.classList.add(classes.HIDE);
				rightNavigator.current.classList.add(classes.SHOW);
				rightNavigator.current.classList.remove(classes.HIDE);
			} else {
				rightNavigator.current.classList.remove(classes.SHOW);
				rightNavigator.current.classList.add(classes.HIDE);
				overlayRef.current.classList.add(classes.HIDE);
			}
		},
		highlightFacetValues.length,
		scrollContainerRef.current,
		300
	);

	const handleNavigator = (e, direction) => {
		if (!scrollContainerRef.current) {
			return;
		}
		const toLeft = direction === DIRECTION.LEFT ? -1 : 1;
		scrollWithRAF(
			scrollContainerRef.current,
			{ distanceX: toLeft * 200 },
			500
		);
		e.target.blur();
	};

	const onSelectItem = (e, item) => {
		if (!dragging.current) {
			addFilter({
				e,
				facetId,
				value: item.value,
				widgetName,
				windowId
			});
		}
	};

	// return if no highLighted facet values
	if (highlightFacetValues.length === 0) {
		return null;
	}

	return (
		<>
			<Navigator
				isMobile={isMobile}
				ref={leftNavigator}
				handleNavigator={handleNavigator}
				direction={DIRECTION.LEFT}
				chipColorClasses={chipColorClasses}
			/>
			<div
				ref={scrollContainerRef}
				className="mb-0 highlight-facet-list d-flex p-0 py-4"
				onMouseDown={(e) => mouseDownHandler(e)}
				onMouseMove={(e) => handleDrag(e)}
				role="presentation"
			>
				{highlightFacetValues.map((item) => (
					<button
						type="button"
						className={`highlight-facet-btn btn-no-decoration chip
							${chipColorClasses}`}
						key={[facetId, item.value].join('-')}
						onClick={(e) => onSelectItem(e, item)}
						onMouseUp={(e) => e.target.blur()}
						aria-label={labels
							.get('FILTER_VALUE_CLICK_TO_SELECT')
							.replace('{filter}', facetLabel)
							.replace('{value}', item.label)}
					>
						{item.label}
						{showCountOnPill === 'true' ? (
							<small className="ddc-font-size-xsmall px-2">
								{item.count}
							</small>
						) : (
							''
						)}
					</button>
				))}
				<div
					aria-hidden
					className={`ghost-element ${isMobile ? 'pr-6' : 'pr-8'}`}
				/>
			</div>
			<div
				aria-hidden
				ref={overlayRef}
				className="blur-overlay py-4"
				style={isMobile ? { width: '32px' } : { width: '48px' }}
			/>
			<Navigator
				isMobile={isMobile}
				ref={rightNavigator}
				handleNavigator={handleNavigator}
				direction={DIRECTION.RIGHT}
				chipColorClasses={chipColorClasses}
			/>
		</>
	);
};

HighlightFacet.propTypes = {
	highlightFacet: PropTypes.instanceOf(PropTypes.shape({})).isRequired,
	chipColorClasses: PropTypes.string,
	showCountOnPill: PropTypes.bool
};

export default HighlightFacet;
