import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { setClassNames } from 'ddc-classnames-js';
import { DIRECTION } from '../global-constants';

const Navigator = (
	{ handleNavigator, direction, isMobile, chipColorClasses = '' },
	ref
) => {
	if (isMobile) {
		return null;
	}

	const isRightNavigator = direction === DIRECTION.RIGHT;

	return (
		<div className={`navigator-${direction}`} ref={ref}>
			<button
				className={setClassNames([
					'chip',
					chipColorClasses,
					'btn-no-decoration',
					'rounded-circle',
					'text-decoration-none',
					'd-inline-flex',
					'justify-content-center',
					'align-items-center'
				])}
				type="button"
				aria-label={`Go to ${
					isRightNavigator ? 'next' : 'previous'
				} item`}
				onClick={(e) => handleNavigator(e, direction)}
				title={`View  ${
					isRightNavigator ? 'next' : 'previous'
				} item of highlight facet`}
			>
				<i
					className={`ddc-icon ddc-icon-arrow4-${direction} pointer-events-none`}
					aria-hidden="true"
				/>
			</button>
		</div>
	);
};

Navigator.propTypes = {
	handleNavigator: PropTypes.func,
	direction: PropTypes.string,
	isMobile: PropTypes.bool,
	chipColorClasses: PropTypes.string
};

export default forwardRef(Navigator);
